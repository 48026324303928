<template>
  <BaseContainer fluid class="max-w-1024">
    <BaseCard class="p-3">
      <CourseChapterItem v-if="finishedLoading" :course-chapter-item="courseChapterItem" />

      <BaseRow v-else>
        <BaseCol cols="12" class="text-center">
          <BaseProgressSpinner />
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </BaseContainer>
</template>

<script>
import { useRoute } from "vue-router";
import useApi from "@use/useApi";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import CourseChapterItem from "./partials/CourseChapterItem";

export default {
  components: { CourseChapterItem },
  setup() {
    const route = useRoute();
    const store = useStore();

    const courseChapter = computed(() => {
      return store.getters["uc/chapters/selectedChapter"];
    });

    const courseChapters = computed(() => {
      return store.getters["uc/chapters/chapterList"];
    });

    const courseChapterItemId = computed(() => route.params.courseChapterItemId);
    const courseChapterItem = ref(null);

    const { isLoading, docs, callApi } = useApi({
      module: "uc",
      method: "showCourseChapterItem",
      onMount: true,
      params: {
        courseChapterItemId: courseChapterItemId.value,
      },
      onSuccess: () => {
        courseChapterItem.value = docs.value.data;
        store.dispatch("uc/chapters/reloadChapterList", true);
        store.dispatch("uc/chapterItems/reloadChapterItemList", true);
      },
    });

    watch(courseChapterItemId, () => {
      if (courseChapterItemId.value) {
        callApi({
          courseChapterItemId: courseChapterItemId.value,
        });
      }
    });

    const finishedLoading = computed(() => {
      return (
        !isLoading.value && courseChapters.value && courseChapter.value && courseChapterItem.value
      );
    });

    return {
      isLoading,
      courseChapterItem,
      courseChapter,
      courseChapters,
      finishedLoading,
    };
  },
};
</script>
