<template>
  <div v-if="results" class="mb-6">
    <BaseMessage v-if="results.success" severity="success" :closable="false">
      {{ $t("uc.my.quiz.success") }}
    </BaseMessage>

    <BaseMessage v-else severity="error" :closable="false">
      {{ $t("uc.my.quiz.failed") }}
    </BaseMessage>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: undefined,
      required: false,
      default: null,
    },
  },
};
</script>
