<template>
  <MultipleChoiceAnswer
    v-for="answer in answers"
    :key="answer.id"
    :answer="answer"
    @select="setSelected"
    @unselect="setUnselected"
    class="mb-2"
  />
</template>

<script>
import MultipleChoiceAnswer from "@/modules/user-course-uc/views/My/Course/Chapter/Item/show/partials/Quiz/partials/MultipleChoiceAnswer";
import { reactive, watch } from "vue";

export default {
  components: { MultipleChoiceAnswer },
  emits: ["selected"],
  props: {
    answers: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selected = reactive({
      ids: [],
    });

    const setSelected = (answerId) => {
      selected.ids.push(answerId);
    };

    const setUnselected = (answerId) => {
      selected.ids = selected.ids.filter((item) => item !== answerId);
    };

    watch(selected, () => {
      emit("selected", selected.ids);
    });

    return {
      setSelected,
      setUnselected,
      selected,
    };
  },
};
</script>
