<template>
  <ExternalVideoViewer
    :type-id="courseChapterItem.model.courseExternalVideoTypeId"
    :url="courseChapterItem.model.url"
  />

  <Controls @backward="backward" @forward="forward" class="mt-6" />
</template>

<script>
import ExternalVideoViewer from "@/modules/haw-components-hac/components/ExternalVideoViewer";
import ChapterItemMixin from "../ChapterItemMixin";

export default {
  name: "ExternalVideo",
  components: { ExternalVideoViewer },
  mixins: [ChapterItemMixin],
};
</script>
