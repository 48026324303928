<template>
  <div v-for="answer in answers" :key="answer.id" class="flex align-items-center mb-2">
    <RadioButton :id="getName(answer.id)" :value="answer.id" v-model="selected" class="mr-2" />
    <label :for="getName(answer.id)">{{ answer.answerText }}</label>
  </div>
</template>

<script>
import RadioButton from "primevue/radiobutton";
import { ref, watch } from "vue";

export default {
  components: { RadioButton },
  emits: ["selected"],
  props: {
    answers: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selected = ref(null);

    const getName = (id) => {
      return `quizAnswer${id}`;
    };

    watch(selected, (value) => {
      emit("selected", [value]);
    });

    return {
      getName,
      selected,
    };
  },
};
</script>
