<template>
  <BaseRteViewer :content="courseChapterItem.model.content" />

  <Controls @backward="backward" @forward="forward" class="mt-6" />
</template>

<script>
import ChapterItemMixin from "../ChapterItemMixin";

export default {
  name: "RTE",
  mixins: [ChapterItemMixin],
};
</script>
