<template>
  <div>
    <BaseParagraph noMargin class="mb-2">
      {{ $t("uc.my.items.show.chapter", { number: chapterIndex + 1 }) }}
    </BaseParagraph>

    <BaseHeadline size="2" fake="6" noMargin class="mb-5 pb-2">
      {{ courseChapterItem.displayName }}
    </BaseHeadline>

    <component
      v-if="component"
      :is="component"
      :course-chapter-item="courseChapterItem"
      :course-chapter="courseChapter"
      @forward="forward"
      @backward="backward"
    />
  </div>
</template>

<script>
import COURSE_CHAPTER_ITEM_MODELS from "@/modules/user-course-uc/constants/COURSE_CHAPTER_ITEM_MODELS";
import RTE from "./RTE";
import Quiz from "./Quiz";
import Video from "./Video";
import ExternalVideo from "./ExternalVideo";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  props: {
    courseChapterItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const courseChapter = computed(() => {
      return store.getters["uc/chapters/selectedChapter"];
    });

    const courseChapters = computed(() => {
      return store.getters["uc/chapters/chapterList"];
    });

    const components = {};

    components[COURSE_CHAPTER_ITEM_MODELS.RTE] = RTE;
    components[COURSE_CHAPTER_ITEM_MODELS.QUIZ] = Quiz;
    components[COURSE_CHAPTER_ITEM_MODELS.VIDEO] = Video;
    components[COURSE_CHAPTER_ITEM_MODELS.EXTERNAL_VIDEO] = ExternalVideo;

    const component = computed(() => {
      if (!props.courseChapterItem) {
        return null;
      }

      return components[props.courseChapterItem.modelName];
    });

    const getIndex = (items, id) => {
      for (let index = 0; index < items.length; index++) {
        if (items[index].id === id) {
          return parseInt(index, 10);
        }
      }

      return null;
    };

    const chapterItemIndex = computed(() => {
      return getIndex(courseChapter.value.courseChapterItems, props.courseChapterItem.id);
    });

    const chapterIndex = computed(() => {
      return getIndex(courseChapters.value, courseChapter.value.id);
    });

    const pushToCourseChapterItem = async (id) => {
      try {
        await router.push({
          name: "MyCoursesShowChapterItem",
          params: {
            courseId: route.params.courseId,
            courseChapterId: route.params.courseChapterId,
            courseChapterItemId: id,
          },
        });
      } catch {
        return;
      }
    };

    const step = (addition) => {
      let index = chapterItemIndex.value + addition;

      if (index >= courseChapter.value.courseChapterItems.length) {
        index = 0;
      } else if (index < 0) {
        index = courseChapter.value.courseChapterItems.length - 1;
      }

      pushToCourseChapterItem(courseChapter.value.courseChapterItems[index].id);
    };

    const forward = () => {
      step(1);
    };

    const backward = () => {
      step(-1);
    };

    return {
      component,
      chapterItemIndex,
      chapterIndex,
      forward,
      backward,
      courseChapter,
    };
  },
};
</script>
