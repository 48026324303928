<template>
  <div class="flex justify-content-between align-items-center w-full">
    <BaseButton @click="backward" icon="pi pi-angle-left" />
    <slot />
    <BaseButton @click="forward" icon="pi pi-angle-right" />
  </div>
</template>

<script>
export default {
  setup(props, { emit }) {
    const backward = () => {
      emit("backward");
    };

    const forward = () => {
      emit("forward");
    };

    return {
      backward,
      forward,
    };
  },
};
</script>
