<template>
  <div>
    <MultipleChoiceAnswers v-if="isMultipleChoice" :answers="answers" @selected="setSelected" />
    <SingleChoiceAnswers v-else :answers="answers" @selected="setSelected" />
  </div>
</template>

<script>
import { computed } from "vue";
import COURSE_QUIZ_TYPES from "@/modules/user-course-uc/constants/COURSE_QUIZ_TYPES";
import MultipleChoiceAnswers from "@/modules/user-course-uc/views/My/Course/Chapter/Item/show/partials/Quiz/partials/MultipleChoiceAnswers";
import SingleChoiceAnswers from "@/modules/user-course-uc/views/My/Course/Chapter/Item/show/partials/Quiz/partials/SingleChoiceAnswers";

export default {
  components: { SingleChoiceAnswers, MultipleChoiceAnswers },
  emits: ["selected"],
  props: {
    answers: {
      type: Array,
      required: true,
      default: () => [],
    },
    type: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isMultipleChoice = computed(() => {
      return props.type === COURSE_QUIZ_TYPES.MULTIPLE_CHOICE;
    });

    const setSelected = (selected) => {
      emit("selected", selected);
    };

    return {
      isMultipleChoice,
      setSelected,
    };
  },
};
</script>
