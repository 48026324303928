<template>
  <div class="flex align-items-center">
    <Checkbox :id="getName(answer.id)" v-model="isChecked" binary class="mr-2" />
    <label :for="getName(answer.id)">{{ answer.answerText }}</label>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import Checkbox from "primevue/checkbox";

export default {
  components: { Checkbox },
  props: {
    answer: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isChecked = ref(false);

    watch(isChecked, () => {
      if (isChecked.value) {
        emit("select", props.answer.id);
      } else {
        emit("unselect", props.answer.id);
      }
    });

    const getName = (id) => {
      return `quizAnswer${id}`;
    };

    return {
      isChecked,
      getName,
    };
  },
};
</script>
