<script>
import Controls from "./Controls";

export default {
  components: { Controls },
  emits: ["backward", "forward"],
  props: {
    courseChapterItem: {
      type: Object,
      required: false,
      default: null,
    },
    courseChapter: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    backward() {
      this.$emit("backward");
    },
    forward() {
      this.$emit("forward");
    },
  },
};
</script>
