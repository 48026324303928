<template>
  <div>
    <BaseParagraph class="font-bold mb-3" noMargin>
      {{
        $t("uc.my.quiz.question", {
          number: index + 1,
        })
      }}
      <br />
      {{ question.questionText }}
    </BaseParagraph>

    <BaseParagraph v-if="question.tooltip" class="mb-3" noMargin>
      {{ $t("uc.my.quiz.tooltip") }}
      <br />
      {{ question.tooltip }}
    </BaseParagraph>

    <p v-if="hasResultError" class="p-error mb-3 pb-1">
      {{ $t("uc.my.quiz.mistake") }}
    </p>

    <Answers
      :answers="question.courseQuizQuestionAnswers"
      :type="question.courseQuizQuestionTypeId"
      @selected="setSelected"
      class="pb-3"
    />
  </div>
</template>

<script>
import Answers from "./Answers";
import { computed } from "vue";

export default {
  components: { Answers },
  props: {
    question: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    index: {
      type: Number,
      required: true,
    },
    results: {
      type: undefined,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const setSelected = (selected) => {
      emit("selected", {
        id: props.question.id,
        values: selected,
      });
    };

    const hasResultError = computed(() => {
      return (
        props.results && props.results.questions && !props.results.questions[props.question.id]
      );
    });

    return {
      setSelected,
      hasResultError,
    };
  },
};
</script>
