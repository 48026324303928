<template>
  <QuizResultLabel :results="results" />

  <Question
    v-for="(question, index) in courseChapterItem.model.courseQuizQuestions"
    :key="index"
    :question="question"
    :index="index"
    :results="results"
    @selected="setSelected"
  />

  <Controls @backward="backward" @forward="forward" class="mt-6">
    <BaseButton @click="submit" :disabled="!canSubmit" label="uc.my.quiz.submit" />
  </Controls>
</template>

<script>
import Question from "./partials/Question";
import { computed, reactive, ref } from "vue";
import useApi from "@use/useApi";
import QuizResultLabel from "./partials/QuizResultLabel";
import ChapterItemMixin from "../ChapterItemMixin";
import { useStore } from "vuex";

export default {
  name: "Quiz",
  components: { QuizResultLabel, Question },
  mixins: [ChapterItemMixin],
  setup(props) {
    const store = useStore();

    const results = ref(null);
    const form = reactive({
      courseQuizId: props.courseChapterItem.model.id,
      answers: {},
    });

    const { isLoading, callApi, docs } = useApi({
      module: "uc",
      method: "checkCourseQuiz",
      onSuccess: () => {
        results.value = docs.value;

        if (results.value.success) {
          store.dispatch("uc/chapters/reloadChapterList", true);
          store.dispatch("uc/chapterItems/reloadChapterItemList", true);
        }
      },
    });

    const setSelected = (data) => {
      if (!data.values.length) {
        delete form.answers[data.id];
      } else {
        form.answers[data.id] = data.values;
      }
    };

    const submit = () => {
      callApi(form);
    };

    const canSubmit = computed(() => {
      const keys = Object.keys(form.answers);

      return props.courseChapterItem.model.courseQuizQuestions.every((question) => {
        return keys.includes(question.id.toString());
      });
    });

    return {
      setSelected,
      form,
      isLoading,
      submit,
      canSubmit,
      results,
    };
  },
};
</script>
