<template>
  <VideoViewer
    :thumbnail="courseChapterItem.model.thumbnailUrl"
    :video="courseChapterItem.model.url"
  />

  <Controls @backward="backward" @forward="forward" class="mt-6" />
</template>

<script>
import VideoViewer from "@/modules/haw-components-hac/components/VideoViewer";
import ChapterItemMixin from "../ChapterItemMixin";

export default {
  name: "Video",
  components: { VideoViewer },
  mixins: [ChapterItemMixin],
};
</script>
